.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-wrapper {
    max-width: 1240px;
    padding: 0 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}


/* CATEGORY STYLE */
.home-categories {
    padding: 20px;
    width: 330px;
    background: #F8F8F8;
    border-radius: 5px;
    position: sticky;
    top: 100px;
}

.home-categories h2 {
    font-size: 20px;
}

.home-category-line {
    width: 60px;
    height: 2px;
    border-radius: 1px;
    margin: 5px 0;
    background: var(--primary);
}

.home-category-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px 0;
}

.home-category-item {
    padding: 6px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #222;
    font-size: 15px;
    transition: color 0.5s;
}

.home-category-item img {
    width: 25px;
    height: 25px;
    object-fit: contain;
    margin-right: 10px;
}

.home-category-item:hover {
    color: var(--primary)
}

.home-category-item.active {
    color: var(--primary);
    font-weight: bold;
}

.home-category-link {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.home-category-link a {
    padding: 6px 0;
    font-size: 15px;
    color: #222;
    transition: color 0.5s;
}

.home-category-link a:hover {
    color: var(--primary)
}


/* HOME CONTENT STYLE */
.home-content {
    width: calc(100% - 350px);
    margin-left: 20px;
}




/* SLIDER STYLES */
.slider {
    margin-bottom: 30px;
}

.big-slide {
    width: 100%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}

.big-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.big-slide-inner {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 30px;
    width: 55%;
}

.big-slide h2 {
    font-weight: bold;
    font-size: 40px;
    color: #222;
    line-height: 40px;
    color: white;
}

.big-slide p {
    font-size: 14px;
    color: #4A5568;
    margin-top: 10px;
    color: white;
}

.big-slide-button {
    padding: 10px 20px;
    background: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    margin-top: 15px;
    align-self: flex-start;
    transition: transform 0.5s;
}

.big-slide-button:hover {
    transform: scale(1.05);
}

.big-slide-sale {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}

.big-slide-sale span {
    font-size: 14px;
    color: #fff;
}

.big-slide-sale-percentage {
    background: linear-gradient(90deg, rgba(255, 114, 114, 0.15) 0%, rgba(255, 114, 114, 0) 79%);
    color: #FF4F4F;
    height: 36px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 14px;
}



/* MINI SLIDES */
.slides {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slide-item {
    width: 31.5%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.5s;
}

.slide-item:hover {
    transform: scale(1.05);
}

.slide-item img {
    width: 100%;
}

.slide-item-box {
    position: absolute;
    left: 0;
    top: 20px;
    height: 70px;
    background: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 30%;
    padding: 10px;
    border-radius: 0 35px 35px 0;
}

.slide-item-box-sale {
    font-size: 13px;
    color: #FF4F4F;
}

.slide-item-box p {
    font-size: 13px;
    font-weight: bold;
}

.slide-item-button {
    position: absolute;
    left: 30px;
    bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}





/* HOME TITLE STYLES */
.home-title h2 {
    font-size: 26px;
    font-weight: bold;
}

.home-title img {
    width: 120px;
    height: 22px;
    object-fit: contain;
    margin-top: 2px;
}

.home-title p {
    font-size: 14px;
    color: #4A5568
}


/* TOP PRODUCTS */
.top-products {
    margin-top: 40px;
}

.top-products-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    margin-top: 20px;
}

.top-product-item {
    width: 20%;
    border: 0.5px solid rgba(74, 85, 104, 0.3);
}




/* Horizontal Category */
.horizontal-category {
    margin-top: 40px;
}


.horizontal-category-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-left: -5px;
}

.horizontal-category-item {
    width: 14.2857142857%;
    margin: 0 5px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F8F8F8;
    border-radius: 5px;
    transition: background-color 0.5s;
}

.horizontal-category-item:hover {
    background: var(--primary);
}

.horizontal-category-item:hover img {
    filter: invert(100%)
}

.horizontal-category-item:hover span {
    color: white;
}

.horizontal-category-item img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    transition: filter 0.5s;
}

.horizontal-category-item span {
    font-size: 12px;
    margin-top: 10px;
    font-weight: normal;
    color: #222;
    text-align: center;
    transition: color 0.5s;
}





/* Banner styles */
.banners {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}

.banner {
    width: 49%;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: transform 0.5s;
}

.banner:hover {
    transform: scale(1.05)
}

.banner img {
    width: 100%;
}

.banner-box {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 20px;
}

.banner-box h2 {
    font-size: 22px;
    font-weight: bold;
    color: #222
}

.banner-box p {
    font-size: 18px;
    font-weight: 400;
    color: var(--primary)
}

.banner-button {
    background: #FF4F4F;
    padding: 7px 15px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    margin-top: 4px;
}






@media screen and (max-width: 768px) {

    .home-categories {
        display: none;
    }

    .home-content {
        width: 100%;
        margin-left: 0;
    }

    .slider {
        margin-bottom: 0;
    }

    .slides {
        display: none;
        width: 300%;
        margin-top: 0;
    }

    .slide-item {
        width: 100%;
    }

    .slide-item img{
        height: 200px;
        width: 100%;
        object-fit: cover;
    }

    .big-slide {
        width: 100%;
    }

    .big-slide-inner {
        width: 70%;
    }

    .big-slide h2 {
        font-size: 16px;
        line-height: 16px;
    }

    .big-slide p {
        font-size: 12px;
        margin-top: 4px;
    }

    .big-slide img {
        height: 200px;
        width: 100%;
        object-fit: cover;
    }

    .big-slide-sale {
        margin-bottom: 0px;
    }

    .big-slide-button {
        font-size: 12px;
    }


    .top-product-item {
        width: 50%;
    }

    .horizontal-category .home-title,
    .horizontal-category-box {
        display: none;
    }

    .banners {
        flex-direction: column;
        margin-top: 0;
    }

    .banner {
        width: 100%;
        margin: 10px 0
    }

    .banner-box h2 {
        font-size: 16px;
    }

    .banner-box p {
        font-size: 14px;
    }

}





.about {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-wrapper {
    width: 1240px;
    padding: 0 20px;
}

.about-wrapper h1 {
    font-size: 30px;
    color: #222;
    padding-bottom: 10px;
}

.about-wrapper p {
    font-size: 14px;
    margin: 12px 0;
    color: #333;
    text-indent: 20px;
}

.about-box {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
}

.about-box-text {
    width: 50%;
    padding-right: 30px;
}

.about-box img {
    margin-top: 20px;
    width: 50%;
    object-fit: contain;
    border-radius: 20px;
}

@media screen and (max-width: 768px) {

    .about-wrapper h1 {
        margin-bottom: 0;
    }

    .about-box {
        flex-direction: column;
        margin-top: 5px;
    }

    .about-box-text {
        width: 100%;
        padding-right: 0;
    }
    
    .about-box img {
        width: 100%;
    }
}




.privacy {
    display: flex;
    justify-content: center;
    align-items: center;
}

.privacy-wrapper {
    width: 1240px;
    padding: 20px;
}

.privacy-wrapper h1 {
    font-size: 26px;
    color: #222;
    padding-bottom: 10px;
    text-align: center;
}

.privacy-wrapper p {
    font-size: 14px;
    margin: 12px 0;
    color: #333;
}

.privacy-space {
    height: 20px;
}
